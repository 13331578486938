import { useCallback, useEffect } from 'react';
import { Ypt_Events_type, emitter } from './global-events';

export const useAddEventsListener = (
  type: Ypt_Events_type,
  handler: () => void
) => {
  const fn = useCallback(handler, []);

  useEffect(() => {
    emitter.on(type, fn);
    return () => {
      emitter.off(type, fn);
    };
  }, []);
};
