import {
  GetAllGPTsList_Rsp_Item,
  http_GPTsList,
  http_delGPTs,
} from "@/http/gpts";
import { useAuthAxios } from "@/http/useAuthAxios";
import { CacheObject } from "@/utils/cache-object";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUpdate } from "react-use";

// 定义表单的类型接口
interface FormData {
  title: string;
  description: string;
  csv_files: FileList | null;
  // 后端保存的文件列表
  db_files?: {
    id: string;
    name: string;
  }[];
}

type GPTsListData_T = {
  GPTsList: GetAllGPTsList_Rsp_Item[];
};

export const GPTsListData = new CacheObject<GPTsListData_T>();

export const useGPTsAssist1 = () => {
  const render = useUpdate();

  const ins = useAuthAxios();

  // 使用 useForm 钩子
  const {
    getValues,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const authAxios = useAuthAxios();

  const selectedFiles = getValues("csv_files");
  console.log("[GPTs]selectedFiles", selectedFiles);
  // const [selectedFiles, setSelectedFiles] = useState([]);

  async function fetchGPTsList() {
    const res = await http_GPTsList(authAxios);
    console.log("fetchGPTsList==", res.data);

    const list = res.data?.GPTsList || [];

    // let list = (res.data as LeftMenuItem[]) || [];

    GPTsListData.updateData({
      GPTsList: list,
    });

    render();
  }

  async function delGPTs(id: string) {
    const res = await http_delGPTs(authAxios, id);
    console.log("delGPTs==", res);

    // render();
    fetchGPTsList();
  }

  const onDel = (id: any) => {
    delGPTs(id);
  };

  // 定义提交表单的函数
  const onSubmit = async (data: FormData) => {
    // 在这里处理表单数据，比如发送到后端或者显示在控制台
    console.log(data);

    var bodyFormData = new FormData();
    bodyFormData.append("title", data.title);
    bodyFormData.append("description", data.description);

    if (data.csv_files) {
      // 使用循环来依次追加每个文件
      for (let i = 0; i < data.csv_files.length; i++) {
        const currFile = data.csv_files[i];
        bodyFormData.append("csv_files", currFile);
      }
    }
    // debugger;

    const res = await authAxios.post("/api/gpts/createAssistant", bodyFormData);

    fetchGPTsList();
  };

  const { GPTsList: assistList = [] } = GPTsListData.getData() || {};

  // 定义选择文件的函数
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handleFileChange", event.target?.files);
    // 获取选择的文件
    const files = event.target.files;
    // 触发表单的 onChange 事件，更新 csv_file 字段的值
    setValue("csv_files", files || null);
    render();
  };

  useEffect(() => {
    fetchGPTsList();
  }, []);

  return {
    reset,
    control,
    setValue,
    errors,
    handleSubmit,
    onSubmit,
    onDel,
    handleFileChange,
    assistList,
  };
};
