import { useEffect, useRef } from "react";
import { useTrailingThrottleFn } from "./common/useTrailingThrottleFn";

export const useResizeElement = (
  handler: (v: { newWidth: number; newHeight: number }) => void
) => {
  const changeHandler = useTrailingThrottleFn(handler);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentDiv = divRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        changeHandler({
          newHeight: entry.contentRect.height,
          newWidth: entry.contentRect.width,
        });
      }
    });

    if (currentDiv) {
      resizeObserver.observe(currentDiv);
    }

    return () => {
      if (currentDiv) {
        resizeObserver.unobserve(currentDiv);
      }
    };
  }, []);

  return {
    ref: divRef,
  };
};
