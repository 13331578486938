import axios from "axios";
import { genApiUrl } from "../utils/http";
import { throttle } from "lodash-es";
import { LeftMenuData, LeftMenuItem } from "@/logic/chat";

const _fetchChats = async (
  options: { Authorization?: string }
) => {
  try {
    const res = await axios.get(genApiUrl("/api/chat/getChats"), {
      headers: { ...options },
    });
    let list = res.data as LeftMenuItem[] || [];

    const setChats = (newChats: LeftMenuItem[]) => {
      LeftMenuData.updateData({
        list: newChats,
      });
      LeftMenuData.getData().render?.();
    };

    setChats(list)
  } catch (err) {
    console.log(err);
  }
};

export const fetchChats = throttle(_fetchChats, 300, {
  leading: false,
  trailing: true,
});
