import { v4 as uuidv4 } from "uuid";

export const getDBUniqId = () => {
  return (
    hex(Date.now() / 1000) +
    " ".repeat(16).replace(/./g, () => hex(Math.random() * 16))
  );
};

function hex(value: number) {
  return Math.floor(value).toString(16);
}

export const genUuid = () => {
  return uuidv4();
};

export function isDev() {
  return process.env.REACT_APP_NODE_ENV === "development";
}

/**
 *
 * @returns example: "2023-10-29T16:31:12.158+00:00"
 */
export const genCurrISOTime = () => {
  let now = new Date();
  let isoString = now.toISOString();
  // let formattedString = isoString.replace("Z", "+00:00");
  // console.log(formattedString);

  return isoString;
};

export function arrayToObject<T>(arr: T[], key: string): Record<string, T> {
  let result: Record<string, any> = {};
  for (let item of arr) {
    result[(item as any)[key]] = item;
  }
  return result;
}
