import { CacheObject } from "@/utils/cache-object";

export interface IUser {
  email: string;
  password: string;
  verified: boolean;
  enableGPT4?: boolean;
  isSuperAdmin?: boolean;
  verificationToken?: string;
  expiresAt?: Date;
}

type AuthUserData_T = {
  user: Partial<IUser>;
};

export const AuthUserData = new CacheObject<AuthUserData_T>({
  user: {},
});
