import { ChatMsgItem, ListItemBaseObj } from "@/logic";
import { arrayToObject, genCurrISOTime, getDBUniqId } from "@/utils";
import { debounce } from "lodash-es";
import { ChatCompletionRole } from "openai/resources/chat/completions";
import { emitter } from "@/events/global-events";
import { CacheObject } from "../utils/cache-object";
import { socket } from "@/http/io";
import { useDebounceFn } from "@/hooks/common/useDebounceFn";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchChats } from "@/http/chat";

type MainChatData_T = {
  render?: () => void;
  /**
   * all msgs map, key is chatID, value is msg list
   */
  msgsMap: Record<string, ChatMsgItem[]>;
};
export const MainChatData = new CacheObject<MainChatData_T>({
  msgsMap: {},
});

export const selectChat = (chatID: string, doRender = true) => {
  const { render: renderMain } = MainChatData.getData();

  // LeftMenuData.updateData({
  //   currChatId: chatID,
  // });

  doRender && renderMain?.();
};

export type LeftMenuItem = {
  title: string;
  // msgs: Array<ChatMsgItem>;
} & ListItemBaseObj;

type LeftMenuData_T = {
  list: LeftMenuItem[];
  render?: () => void;
  // currChatId?: string;
};

export const LeftMenuData = new CacheObject<LeftMenuData_T>({
  list: [] as LeftMenuItem[],
});

type MsgMainData_T = {
  renderMsgList?: () => void;
};
export const MsgMainData = new CacheObject<MsgMainData_T>();

export const isEmptyChatId = (chatID?: string) => {
  return !chatID;
};

export const removeChat = (chatID: string, doRender = true) => {
  // const { render: renderMain } = MainChatData.getData();
  // const { list } = LeftMenuData.getData();
  // const delIndex = list.findIndex((v) => v.chatID === chatID);
  // ~delIndex && list.splice(delIndex, 1);
  // const isSelectedChat = currChatId && chatID === currChatId;
  // LeftMenuData.updateData({
  //   list,
  //   ...(isSelectedChat ? { currChatId: "" } : {}),
  // });
  // saveLocalStorage();
  // doRender && renderMain?.();
};

export const removeMsg = (msgList: ChatMsgItem[], msgYId: string) => {
  const delIndex = msgList.findIndex((v) => v._id === msgYId);
  ~delIndex && msgList.splice(delIndex, 1);
  return msgList;
};

export const newChat = ({ _id, title }: LeftMenuItem, doRender = true) => {
  const { render: renderMenu } = LeftMenuData.getData();

  let newChat = {
    title,
    _id,
    sentTime: genCurrISOTime(),
  };

  LeftMenuData.updateData({
    list: mergeChatList([newChat]),
  });

  doRender && renderMenu?.();
};

export const updateStreamMessage = (
  chatID: string,
  msg: ChatMsgItem,
  doRender = true
) => {
  if (!chatID) return;

  const { render: renderMain } = MainChatData.getData();

  const currChat = getChatById(chatID);
  let oldMsgs = getMsgListByChatId(chatID);

  const index = oldMsgs.findIndex((v) => v._id === msg._id);
  if (currChat && ~index) {
    // return  a new item
    oldMsgs[index] = {
      ...oldMsgs[index],
      content: msg.content,
    };

    // delete loading
    delete oldMsgs[index].isLoading;

    updateMsgList(currChat._id, oldMsgs);
  }

  emitter.emit("Ypt_msg_list_scrollBottom");
  doRender && renderMain?.();
};

export const newMessageError = (chatID: string, msg: ChatMsgItem) => {
  if (!chatID) return;
  const currChat = getChatById(chatID);
  if (currChat) {
    let existingMsgList = getMsgListByChatId(chatID);
    const currMsg = existingMsgList.find((v) => v._id === msg._id);
    if (currMsg) {
      msg.content += currMsg.content || "";
      newMessage(chatID, msg);
    }
  }
};

export const newMessage = (
  chatID: string,
  msg: ChatMsgItem,
  doRender = true
) => {
  if (!chatID) return;
  const { render: renderMain } = MainChatData.getData();
  const currChat = getChatById(chatID);

  if (currChat) {
    let oldMsgs = mergeMsgList(chatID, [msg])!;
    updateMsgList(currChat._id, oldMsgs);
  }

  emitter.emit("Ypt_msg_list_scrollBottom");
  doRender && renderMain?.();
};

const MaxMsgLoadingMS = 12_000;

export const shouldRemoveLoadingMsg = (sentTime?: string) => {
  return (
    sentTime && Date.now() - new Date(sentTime).getTime() > MaxMsgLoadingMS
  );
};

export const removeLoadingMessage = (chatID: string, msgYid: string) => {
  const chat = getChatById(chatID);

  let msgs = getMsgListByChatId(chatID);
  if (chat && msgs?.length) {
    const list = removeMsg(msgs, msgYid);
    updateMsgList(chatID, list);
  }
};

export const removeExpiredLoadingMsg = () => {
  const { list } = LeftMenuData.getData();

  let doRender = false;
  list.forEach((chat) => {
    let msgs = getMsgListByChatId(chat._id);
    msgs?.forEach((msg) => {
      const { sentTime, _id } = msg;
      if (shouldRemoveLoadingMsg(sentTime)) {
        removeLoadingMessage(chat._id, _id!);
        doRender = true;
      }
    });
  });

  doRender && MsgMainData.getData()?.renderMsgList?.();
};

export const updateChat = (newChat: LeftMenuItem) => {
  const { list } = LeftMenuData.getData();
  const index = list.findIndex((v) => v._id === newChat._id);
  if (~index) {
    list[index] = newChat;
  }
};

// export const getCurrChat = () => {
//   const { list, currChatId } = LeftMenuData.getData();
//   if (!currChatId) return undefined;

//   return list.find((v) => v.chatID === currChatId);
// };

export const getChatById = (chatID: string) => {
  if (!chatID) return undefined;
  const { list } = LeftMenuData.getData();
  return list.find((v) => v._id === chatID);
};

export const saveLocalStorage = debounce(() => {
  // const { list } = LeftMenuData.getData();
  // LocalStorageManager.save('menuList', list);
}, 200);

export const getCurrMsgList = () => {
  const chatID = getChatIdFromUrl();
  return getMsgListByChatId(chatID);
};

export const getMsgListByChatId = (chatID: string) => {
  return MainChatData.getData().msgsMap[chatID] || [];
};

export const updateMsgList = (chatID: string, newMsgList: ChatMsgItem[]) => {
  const { msgsMap: oldMap } = MainChatData.getData();
  oldMap[chatID] = newMsgList;
  MainChatData.updateData({
    msgsMap: oldMap,
  });
};

// export const genCurrChatId = () => {
//   const { currChatId } = LeftMenuData.getData();

//   return currChatId;
// };

export const genMsgItem = ({
  content,
  role = "user",
  _id,
  chatID,
}: {
  content: string;
  role?: ChatCompletionRole;
  _id?: string;
  chatID: string;
}) => {
  return {
    content,
    role,
    _id: _id || getDBUniqId(),
    sentTime: genCurrISOTime(),
    chatID,
  } as ChatMsgItem;
};

export const genLoadingMsgItem = (_id: string, chatID: string) => {
  return {
    isLoading: true,
    sentTime: genCurrISOTime(),
    _id,
    role: "assistant",
    chatID,
  } as ChatMsgItem;
};

const MaxTokenLength = 5000;
const MaxMsgLength = 30;

function sliceArrayByToken(arr: Array<ChatMsgItem>): Array<ChatMsgItem> {
  let sumLength = 0;
  const result: Array<ChatMsgItem> = [];

  // 从数组最后一个元素开始逆序遍历
  for (let i = arr.length - 1; i >= 0; i--) {
    const element = arr[i];
    const contentLength = element.content?.length || 0;

    // 如果添加当前元素后不超过 2000 字符
    if (sumLength + contentLength <= MaxTokenLength) {
      result.unshift(element); // 将元素添加到结果数组的开头
      sumLength += contentLength;
    } else {
      break; // 超过 2000 字符，停止遍历
    }
  }

  return result;
}

export const filterAjaxMsgList = (currMsgList: ChatMsgItem[]) => {
  const arr1 = currMsgList
    .filter((v) => !v.isLoading && v.content)
    .map((c) => {
      return {
        content: c.content,
        role: c.role,
      } as ChatMsgItem;
    });

  let arr2 = sliceArrayByToken(arr1);

  if (arr2.length > MaxMsgLength) {
    arr2 = arr2.slice(arr2.length - MaxMsgLength);
  }

  if (!arr2.length && arr1.length) {
    arr2 = [arr1[arr1.length - 1]];
  }

  return arr2;
};

export const getChatIdFromUrl = () => {
  return window.location.pathname.split("/")[2];
};

export const useFectchMsgs = () => {
  const authHeader = useAuthHeader();

  const fetchMessages = async (chatID: string) => {
    if (!chatID) return;

    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/chat/getMessagesByChatID/${chatID}`,
      { headers: { Authorization: authHeader(), "socket-id": socket.id } }
    );
    const msgList = res.data as ChatMsgItem[];

    const newMsgList = mergeMsgList(chatID, msgList);
    updateMsgList(chatID, newMsgList!);
    // setMessages(data.reverse());

    if (chatID === getChatIdFromUrl()) {
      MainChatData.getData().render?.();
    }
  };

  return fetchMessages;
};

function mergeChatList(newChatList: LeftMenuItem[]) {
  let currChatList = LeftMenuData.getData().list;

  if (!newChatList?.length) return currChatList;
  let newChatMap = arrayToObject(newChatList, "_id");

  let currChatListNewer = currChatList.map((currChat) => {
    let newerChat = newChatMap[currChat._id];
    if (newerChat) {
      currChat = {
        ...currChat,
        ...newerChat,
      };
      delete newChatMap[currChat._id];
    }
    return currChat;
  });

  const mergedList = derepeatAndOrderList(
    currChatListNewer,
    Object.values(newChatMap)
  ) as LeftMenuItem[];

  return mergedList;
}

function mergeMsgList(chatID: string, newMsgList: ChatMsgItem[]) {
  let currMsgList = getMsgListByChatId(chatID);

  if (!newMsgList?.length) return currMsgList;

  let newMsgMap = arrayToObject(newMsgList, "_id");

  let currMsgListNewer = currMsgList.map((currMsg) => {
    let newerMsg = newMsgMap[currMsg._id];
    if (newerMsg) {
      currMsg = {
        ...currMsg,
        ...newerMsg,
      };

      // delete loading
      if (newerMsg.error || (newerMsg.createdAt && currMsg.isLoading)) {
        delete currMsg.isLoading;
      }

      delete newMsgMap[currMsg._id];
    }
    return currMsg;
  });

  const mergedList = derepeatAndOrderList(
    currMsgListNewer,
    Object.values(newMsgMap)
  ) as ChatMsgItem[];

  return mergedList;
}

export function derepeatAndOrderList(
  oldArr: ListItemBaseObj[],
  newArr: ListItemBaseObj[]
) {
  // 合并两个数组
  let combined = oldArr.concat(newArr);
  // 根据_id去重
  let unique = combined.reduce((acc, current) => {
    const x = acc.find((item) => item._id === current._id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [] as ListItemBaseObj[]);

  // 根据createTime或sentTime排序
  unique.sort((a, b) => {
    let aTime = a.sentTime ? a.sentTime : a.createdAt!;
    let bTime = b.sentTime ? b.sentTime : b.createdAt!;
    return aTime.localeCompare(bTime);
  });

  return unique;
}

let isInitMsgListener = false;
export const useInitMsgListener = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authHeader = useAuthHeader();

  const fetchMessages = useFectchMsgs();

  const delayFetchMesgs = useDebounceFn(fetchMessages);

  if (isInitMsgListener) return;
  isInitMsgListener = true;

  const getChats = () => {
    const authHeaderV = authHeader();
    fetchChats({ Authorization: authHeaderV });
  };

  // listenMsg
  socket.on("newMessage", (data: { chatID: string }) => {
    const id = getChatIdFromUrl();
    delayFetchMesgs(id);
  });

  socket.on("newChat", (data: { chatID: string }) => {
    if (window.location.pathname === "/") {
      navigate(`/c/${data.chatID}`);
    }
  });

  socket.on("updatedChats", () => {
    // console.log("updatedChats");
    getChats();
  });

  socket.on("chatgptResChunk", (data: { chatID: string; content: string }) => {
    // console.log("chatgptResChunk", data.chatID, data.content);
    const id = getChatIdFromUrl();
    if (data.chatID === id) {
      updateStreamMessage(id, data as ChatMsgItem);
    }
  });

  socket.on(
    "resError",
    (data: { chatID: string; error: string; msgId: string }) => {
      const id = getChatIdFromUrl();
      if (data.chatID === id) {
        newMessageError(id, {
          _id: data.msgId,
          content: t("msg_error"),
          error: data.error
        } as ChatMsgItem);
      }
    }
  );
};
