import React, { useState, useEffect, useMemo } from "react";
import { AppBar, Toolbar, Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { ThemeProvider } from "@mui/material/styles";
import forestTheme from "../forestTheme";
import axios from "axios";
import { useAuthHeader, useAuthUser, useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { genApiUrl } from "../utils/http";
import { socket } from "../http/io";
import { fetchChats } from "../http/chat";
import { useUpdate } from "react-use";
import { LeftMenuData } from "@/logic/chat";
import GPTVersionBar from "./gpt-version-bar";
import { AuthUserData } from "@/logic/user";

export type ChatItem = {
  _id: string;
  userID: string;
  title: string;
};
// export type LeftMenuData_T = {
//   chatList: ChatItem[];
//   renderSideBar?: () => void;
// };

// export const LeftMenuData = new CacheObject<LeftMenuData_T>({
//   chatList: [],
// });

const SideBar = (props: { activeChat?: string }) => {
  const render = useUpdate();
  const authUser = useAuthUser();

  useMemo(() => {
    LeftMenuData.updateData({
      render,
    });
  }, []);

  const headerHeight = "74px"; // approximate
  const footerHeight = "105px"; // approximate

  const authHeader = useAuthHeader();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const { list: chats } = LeftMenuData.getData();

  const { enableGPT4 } = AuthUserData.getData().user || {};

  const GPTVersion = enableGPT4 ? "GPT-4" : "GPT-3.5";

  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleNewChat = () => {
    navigate("/");
  };

  const handleLogOut = () => {
    signOut();
  };

  const onChatClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    chatID: string
  ) => {
    e.preventDefault();
    navigate(`/c/${chatID}`);
  };

  const handleClearConversations = async () => {
    if (!deleteConfirmation) {
      setDeleteConfirmation(true);
      return;
    } else {
      setDeleteConfirmation(false);
      await axios.delete(genApiUrl("/api/chat/deleteAllChatsByUserID"), {
        headers: { Authorization: authHeader(), "socket-id": socket.id },
      });
      navigate("/");
    }
  };

  return (
    <div id="SideBar">
      <AppBar
        position="static"
        sx={{
          bgcolor: "#202123",
          height: "100vh",
          width: "260px",
          position: "fixed",
          zIndex: "100",
        }}
      >
        <Toolbar
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            right: "25px",
          }}
        >
          <Stack
            direction="column"
            display="flex"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%", height: "100%", position: "relative" }}
          >
            <ThemeProvider theme={forestTheme}>
              <div className="side-header">
                <Button
                  variant="outlined"
                  color="info"
                  className="new-chat-btn"
                  sx={{
                    textTransform: "none",
                    height: "46px",
                    width: "244px",
                    mt: "10px",
                    borderRadius: "5px",
                    borderColor: "#555559",
                    justifySelf: "center",
                    "&:hover": { borderColor: "#555559" },
                  }}
                  onClick={handleNewChat}
                >
                  <AddIcon
                    fontSize="small"
                    sx={{ position: "relative", right: "70px", bottom: "1px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "0.83rem",
                      fontFamily: "Noto Sans, sans-serif",
                      position: "relative",
                      right: "60px",
                    }}
                  >
                    {t("New chat")}
                  </Typography>
                  <GPTVersionBar GPTVersion={GPTVersion} />
                </Button>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  height: "100%",
                  marginTop: headerHeight,
                  marginBottom: footerHeight,
                }}
              >
                <Stack>
                  {chats.map((chat, index) => {
                    const title = chat.title.replaceAll('"', "");
                    return (
                      <div
                        key={chat._id || index}
                        id="chatBtn"
                        style={{
                          width: "244px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                      >
                        <Button
                          key={chat._id}
                          variant="text"
                          color="info"
                          sx={{
                            textTransform: "none",
                            height: "40px",
                            width: "244px",
                            borderRadius: "5px",
                            justifyContent: "left",
                            bgcolor:
                              title === props.activeChat
                                ? "#343541"
                                : "#202123",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            "&:hover": {
                              bgcolor:
                                title === props.activeChat
                                  ? "#343541"
                                  : "#2A2B32",
                            },
                          }}
                          startIcon={
                            <ChatBubbleOutlineRoundedIcon
                              fontSize="small"
                              sx={{ ml: "7px" }}
                            />
                          }
                          onClick={(e) => onChatClick(e, chat._id)}
                          href={`/c/${chat._id}`}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.8rem",
                              fontFamily: "Noto Sans, sans-serif",
                            }}
                          >
                            {title}
                          </Typography>
                        </Button>
                        <div
                          style={{
                            width: "70px",
                            height: "40px",
                            position: "relative",
                            bottom: "40px",
                            left: "174px",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                            background: `linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, ${
                              title === props.activeChat
                                ? "rgba(52, 53, 65, 1)"
                                : "rgba(32, 33, 35, 1)"
                            } 100%)`,
                            pointerEvents: "none",
                          }}
                        />
                      </div>
                    );
                  })}
                </Stack>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "0px",
                  borderTop: "1px solid #4D4D4F",
                  width: "240px",
                  justifySelf: "center",
                }}
              >
                {!deleteConfirmation ? (
                  <Button
                    variant="text"
                    color="info"
                    sx={{
                      textTransform: "none",
                      height: "40px",
                      width: "244px",
                      borderRadius: "5px",
                      justifyContent: "left",
                      mt: "10px",
                      "&:hover": { bgcolor: "#343541" },
                    }}
                    startIcon={
                      <DeleteOutlinedIcon fontSize="small" sx={{ ml: "7px" }} />
                    }
                    onClick={handleClearConversations}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.83rem",
                        fontFamily: "Noto Sans, sans-serif",
                      }}
                    >
                      {t("Clear conversations")}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    color="info"
                    sx={{
                      textTransform: "none",
                      height: "40px",
                      width: "244px",
                      borderRadius: "5px",
                      justifyContent: "left",
                      mt: "10px",
                      "&:hover": { bgcolor: "#343541" },
                    }}
                    startIcon={
                      <CheckRoundedIcon fontSize="small" sx={{ ml: "7px" }} />
                    }
                    onClick={handleClearConversations}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.83rem",
                        fontFamily: "Noto Sans, sans-serif",
                      }}
                    >
                      {t("Confirm clear conversations")}
                    </Typography>
                  </Button>
                )}

                <Button
                  variant="text"
                  color="info"
                  sx={{
                    textTransform: "none",
                    height: "40px",
                    width: "244px",
                    mb: "10px",
                    mt: "5px",
                    borderRadius: "5px",
                    justifyContent: "left",
                    "&:hover": { bgcolor: "#343541" },
                  }}
                  startIcon={
                    <LogoutRoundedIcon fontSize="small" sx={{ ml: "11px" }} />
                  }
                  onClick={handleLogOut}
                >
                  <Typography
                    sx={{
                      fontSize: "0.83rem",
                      fontFamily: "Noto Sans, sans-serif",
                    }}
                  >
                    {t("Log out")}
                  </Typography>
                </Button>
              </div>
            </ThemeProvider>
          </Stack>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default SideBar;

export function useFirstFetchChatList() {
  const authHeader = useAuthHeader();

  const getChats = () => {
    const authHeaderV = authHeader();
    fetchChats({ Authorization: authHeaderV });
  };

  useEffect(() => {
    getChats();
  }, []);
}

export function useFirstFetchUser(done?: () => void) {
  const authHeader = useAuthHeader();

  const getUser = async () => {
    const authHeaderV = authHeader();
    const res = await axios.get(genApiUrl("/api/auth/userInfo"), {
      headers: { Authorization: authHeaderV },
    });
    let user = res.data || {};
    // console.log("user", user.enableGPT4);
    AuthUserData.updateData({
      user,
    });

    LeftMenuData.getData().render?.();

    done?.();
  };

  useEffect(() => {
    getUser();
  }, []);
}
