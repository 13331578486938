import React from "react";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

type Props = {
  //
};

function LoadingMsg(props: Props) {
  return (
    <Typography
      variant="body1"
      sx={{
        color: "#D1D5D2",
        fontFamily: "Noto Sans, sans-serif",
        fontSize: "0.95rem",
        p: "1rem",
        lineHeight: "2",
        mt: "10px",
        mb: "10px",
        maxWidth: "100%",
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin />
    </Typography>
  );
}

export default LoadingMsg;
