import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isDev } from '.';

function addAllCacheObject(_debug_id: string, cacheObject: CacheObject<any>) {
  try {
    const _window = window as any;
    if (_window) {
      if (!_window?.gfe_all_cacheObjects) {
        _window.gfe_all_cacheObjects = {};
      }

      _window.gfe_all_cacheObjects[_debug_id] = cacheObject;
    }
  } catch (error) {}
}

type CacheObjectOptions = {
  _debug_id?: string;
};

export const genCacheObjectUUId = (prefix: string) => {
  return prefix + '--' + uuidv4();
};

export class CacheObject<T extends Record<string, any> = Record<string, any>> {
  constructor(initialValue?: T, options?: CacheObjectOptions) {
    if (initialValue) {
      this.data = initialValue;
    }
    if (options?._debug_id) {
      this._debug_id = options?._debug_id;
      addAllCacheObject(options?._debug_id, this);
    }
  }
  _debug_id?: string;
  data = {} as T;
  updateData = (newReportData: Partial<T>) => {
    if (this._debug_id && isDev()) {
      console.log('[CacheObject] newData:', newReportData);
    }
    this.data = { ...this.data, ...newReportData };
  };

  getData = () => {
    return this.data;
  };

  clearData = () => {
    this.data = {} as any;
  };

  deleteDataByKey = (key?: string) => {
    if (key) {
      delete (this as any).data[key];
    }
  };
}

/**
 * clear data when component unmount
 * @param obj
 */
export const useDestroyCacheData = (obj: CacheObject) => {
  useEffect(() => {
    return () => {
      obj?.clearData();
    };
  }, []);
};
