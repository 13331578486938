import React from "react";
import { Typography } from "@mui/material";

type Props = { line: string };

function NormalMessageLine({ line }: Props) {
  return (
    <Typography
      variant="body1"
      sx={{
        color: "#D1D5D2",
        fontFamily: "Noto Sans, sans-serif",
        fontSize: "0.95rem",
        lineHeight: "1.8",
        pl: "1rem",
        maxWidth: "100%",
      }}
    >
      {line}
    </Typography>
  );
}

export default NormalMessageLine;
