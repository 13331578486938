/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Typography, IconButton, Stack } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import Footer from "../components/footer";
import SideBar, { useFirstFetchChatList, useFirstFetchUser } from "../components/sideBar";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import Icon from "../components/icon";
import Topper from "../components/topper";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import typescript from "highlight.js/lib/languages/typescript";
import python from "highlight.js/lib/languages/python";
import cpp from "highlight.js/lib/languages/cpp";
import java from "highlight.js/lib/languages/java";
import csharp from "highlight.js/lib/languages/csharp";
import php from "highlight.js/lib/languages/php";
import sql from "highlight.js/lib/languages/sql";
import css from "highlight.js/lib/languages/css";
import xml from "highlight.js/lib/languages/xml";
import json from "highlight.js/lib/languages/json";
import dockerfile from "highlight.js/lib/languages/dockerfile";
import markdown from "highlight.js/lib/languages/markdown";
import bash from "highlight.js/lib/languages/bash";
import lua from "highlight.js/lib/languages/lua";
import ruby from "highlight.js/lib/languages/ruby";
import r from "highlight.js/lib/languages/r";
import go from "highlight.js/lib/languages/go";
import c from "highlight.js/lib/languages/c";
import "highlight.js/styles/atom-one-dark.css";
import Modal from "../components/apiKeyModal";
import ApiKey from "../utils/apiKey";
import { socket } from "../http/io";
import CodeMessageLine from "../components/chat-list/code-msg-line";
import { isArray } from "lodash-es";
import NormalMessageLine from "../components/chat-list/normal-msg-line";
import { useOnResizeWidth } from "../hooks/useOnResizeWidth";
import { useUpdate } from "react-use";
import {
  MainChatData,
  getCurrMsgList,
  useFectchMsgs,
  useInitMsgListener,
} from "@/logic/chat";
import { useTrailingThrottleFn } from "@/hooks/common/useTrailingThrottleFn";
import { useAddEventsListener } from "@/events/useEvent";
import LoadingMsg from "@/components/chat-list/loading-msg";

hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("typescript", typescript);
hljs.registerLanguage("python", python);
hljs.registerLanguage("cpp", cpp);
hljs.registerLanguage("java", java);
hljs.registerLanguage("csharp", csharp);
hljs.registerLanguage("php", php);
hljs.registerLanguage("sql", sql);
hljs.registerLanguage("css", css);
hljs.registerLanguage("xml", xml);
hljs.registerLanguage("json", json);
hljs.registerLanguage("dockerfile", dockerfile);
hljs.registerLanguage("markdown", markdown);
hljs.registerLanguage("bash", bash);
hljs.registerLanguage("lua", lua);
hljs.registerLanguage("ruby", ruby);
hljs.registerLanguage("r", r);
hljs.registerLanguage("go", go);
hljs.registerLanguage("c", c);

const PasteIcon = ContentPasteIcon;
const DownIcon = ArrowDownwardRoundedIcon;

const ChatPage = () => {
  const render = useUpdate();
  const { id } = useParams<{ id: string }>();
  const authHeader = useAuthHeader();
  const navigate = useNavigate();

  const scrollDiv = useRef<HTMLDivElement>(null);

  /* IMPORTANT: messages are stored from the oldest to the newest
        [0] is the oldest message, [length - 1] is the newest message */
  // const [messages, setMessages] = useState<
  //   { role: "user" | "assistant"; content: string }[]
  // >([]);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  // const [height, setHeight] = useState<string>("calc(100vh - 64px)");
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [title, setTitle] = useState<string>("");
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(!ApiKey.get());

  const messages = getCurrMsgList();
  // console.log("messages", messages);

  useMemo(() => {
    MainChatData.updateData({
      render,
    });
  }, []);

  const isBigWidth = width > 1000;

  useOnResizeWidth(width, setWidth);

  const handleHeightChange = (footeHeight: number) => {
    setFooterHeight(footeHeight);
  };

  useEffect(() => {
    if (width > 1000) {
      // setHeight("calc(100% - " + footerHeight + "px)");
    } else {
      // setHeight("calc(100% - " + footerHeight + "px - 40px)");
    }
  }, [footerHeight, width]);

  const marginTop = isBigWidth ? "0" : "40px";

  useFirstFetchUser()
  useFirstFetchChatList();
  useInitMsgListener();

  const fetchMessages = useFectchMsgs();

  // at the beginning, get all messages
  useEffect(() => {
    fetchMessages(id!);
  }, [id]);

  const getTitle = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/chat/getChatTitleByID/${id}`,
      { headers: { Authorization: authHeader() } }
    );
    const data = res.data;
    if (data === "Chat not found") {
      navigate("/");
    }
    setTitle(data);
  };

  useEffect(() => {
    setTimeout(() => {
      getTitle();
    }, 100);
    if (title !== document.title) {
      document.title = title;
    }
  }, [title]);

  const scrollToBottom = useTrailingThrottleFn(() => {
    if (scrollDiv.current) {
      scrollDiv.current.scrollTo({
        top: scrollDiv.current.scrollHeight,
        behavior: "auto",
      });
      setTimeout(() => {
        if (scrollDiv.current) {
          scrollDiv.current.scrollTo({
            top: scrollDiv.current.scrollHeight,
            behavior: "auto",
          });
        }
      }, 100);
    }
  });

  useAddEventsListener("Ypt_msg_list_scrollBottom", scrollToBottom);

  const smoothScrollToBottom = () => {
    if (scrollDiv.current) {
      scrollDiv.current.scrollTo({
        top: scrollDiv.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleDivScroll = (e: any) => {
    const bottom: boolean =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom !== scrolledToBottom) {
      setScrolledToBottom(bottom);
    }
  };

  // when page is loaded, scroll to the bottom of the page
  useEffect(() => {
    scrollToBottom();
  }, [width,id]);

  useEffect(() => {}, [socket]);

  useEffect(() => {
    hljs.highlightAll();
  }, [messages, socket]);

  const handleWidthSide = () => {
    if (width < 1000) {
      return "0px";
    } else {
      return "260px";
    }
  };

  const handleWidthMain = () => {
    if (width < 1000) {
      return "100vw";
    } else {
      return "calc(100vw - 260px)";
    }
  };

  const handleMessageWidth = () => {
    if (width < 1000) {
      return "calc(100% - 40px)";
    } else {
      return "45%";
    }
  };

  return (
    <div
      id="ChatPage"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div id="side" style={{ width: handleWidthSide(), height: "100%" }}>
        {width > 1000 && <SideBar activeChat={title} />}
      </div>
      {width < 1000 && <Topper chatTitle={title} />}
      <div
        id="main"
        style={{
          position: "relative",
          width: handleWidthMain(),
          marginTop,
          marginBottom: footerHeight,
          overflowY: "auto",
        }}
        ref={scrollDiv}
        onScroll={handleDivScroll}
      >
        <div id="center" style={{ width: "100%" }}>
          <Stack direction="column" sx={{ width: "100%", height: "100%" }}>
            {messages.map((message, index) => {
              const isLoading = message.isLoading;

              if (
                (message.role === "assistant" &&
                  message.content?.includes("\n" || "```" || "`")) ||
                isLoading
              ) {
                const content: string = message.content!;

                const role = isLoading ? "assistant" : message.role!;

                return (
                  <div
                    key={message._id || index}
                    style={{
                      backgroundColor: "#444654",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Icon role={role as any} />
                    <MsgLines
                      lines={content}
                      isLoading={isLoading}
                      width={handleMessageWidth()}
                    />
                    {width > 1000 && (
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(message.content!);
                        }}
                        sx={{
                          color: "#7F7F90",
                          mt: "26px",
                          width: "25px",
                          height: "25px",
                          borderRadius: "7px",
                          "&:hover": { color: "#D9D9E3" },
                        }}
                      >
                        <PasteIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    )}
                  </div>
                );
              } else {
                return (
                  <div
                    key={message._id || index}
                    style={{
                      backgroundColor:
                        message.role === "user" ? "#343541" : "#444654",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Icon role={message.role as "user" | "assistant"} />
                    <div style={{ width: handleMessageWidth() }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: message.role === "user" ? "white" : "#D1D5D2",
                          fontFamily: "Noto Sans, sans-serif",
                          fontSize: "0.95rem",
                          p: "1rem",
                          lineHeight: "2",
                          mt: "10px",
                          mb: "10px",
                          maxWidth: "100%",
                        }}
                      >
                        {message.content}
                      </Typography>
                    </div>
                    {width > 1000 && (
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(message.content!);
                        }}
                        sx={{
                          color: "#7F7F90",
                          mt: "26px",
                          width: "25px",
                          height: "25px",
                          borderRadius: "7px",
                          "&:hover": { color: "#D9D9E3" },
                        }}
                      >
                        <PasteIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    )}
                  </div>
                );
              }
            })}
          </Stack>
          {!scrolledToBottom && (
            <IconButton
              onClick={smoothScrollToBottom}
              sx={{
                position: "fixed",
                bottom: `${footerHeight + 20}px`,
                right: "25px",
                width: "26px",
                height: "26px",
                bgcolor: "#545661",
                border: "1px solid #656770",
                "&:hover": { bgcolor: "#545661" },
              }}
            >
              <DownIcon fontSize="small" sx={{ color: "#B7B8C3" }} />
            </IconButton>
          )}
        </div>

        <Modal open={open} setOpen={setOpen} />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
      >
        <Footer
          setHeight={handleHeightChange}
          newInput=""
          openModal={() =>
            setTimeout(() => {
              setOpen(true);
            }, 200)
          }
          hasLeftRoom={true}
        />
      </div>
    </div>
  );
};

type RenderMsgLines_Props = {
  lines: string;
  isLoading?: boolean;
  width: string;
};

function _renderMsgLines({ lines, isLoading, width }: RenderMsgLines_Props) {
  if (isLoading) {
    return (
      <div
        style={{
          width,
        }}
      >
        <LoadingMsg />
      </div>
    );
  }

  const newLines = splitMsgLines(lines);

  return (
    <div
      style={{
        width,
        marginBottom: "15px",
        marginTop: "15px",
      }}
    >
      {newLines.map((lineItem, index) => {
        if (isArray(lineItem)) {
          return (
            <CodeMessageLine
              key={"1" + index}
              codeBlock={lineItem as string[]}
            />
          );
        } else {
          return (
            <NormalMessageLine key={"2" + index} line={lineItem as string} />
          );
        }
      })}
    </div>
  );
}

export const MsgLines = React.memo<RenderMsgLines_Props>(
  _renderMsgLines as any
);

const CodeIdentifier = "```";

type ResArr1 = (string | string[])[];

function hasCodeType(input: string) {
  // 使用正则表达式来匹配以"```"开始，以一个字母结束的情况
  const pattern = /^```.+[a-zA-Z]$/;
  return pattern.test(input);
}

function splitMsgLines(str: string) {
  const strArr = str.split("\n" || CodeIdentifier);

  let currItemBuffer = [] as string[];

  let hasLeftDot = false;

  const res = strArr.reduce((p, c, index) => {
    // if found in this line
    let isFound = false;

    let incrementLen = hasCodeType(c.trim())
      ? c.trim().length
      : CodeIdentifier.length;

    let i = -1,
      lastI = 0;
    while (~(i = c.indexOf(CodeIdentifier, i + 1))) {
      isFound = true;
      if (hasLeftDot) {
        currItemBuffer.push(c.slice(lastI, i));

        let codeBlock = [...currItemBuffer];

        if (!codeBlock[0]) {
          codeBlock.splice(0, 1);
        }

        p.push(codeBlock);
        // reset it
        currItemBuffer = [];
      } else {
        p.push(c.slice(lastI, i));
      }

      lastI = i + incrementLen;
      hasLeftDot = !hasLeftDot;
    }

    if (isFound) {
      if (hasLeftDot) {
        // push remaining part
        currItemBuffer.push(c.slice(lastI, i));
      } else {
        p.push(c.slice(lastI, i));
      }
    } else {
      if (hasLeftDot) {
        currItemBuffer.push(c);
      } else {
        p.push(c);
      }
    }

    return p;
  }, [] as ResArr1);

  if (currItemBuffer?.length) {
    res.push([...currItemBuffer]);
  }
  // console.log("splitMsgLines", res);

  return res;
}

export default ChatPage;
