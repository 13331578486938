import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  "zh-CN": {
    translation: {
      "New chat": "新会话",
      "Log out": "登出",
      "Send a message": "问点什么",
      "Clear conversations": "清除所有会话",
      "Confirm clear conversations": "点击确认清除",
      bottom_power_by:
        "由 OpenAI GPT 提供支持。 YoyoChat 可能会产生有关人物、地点或事实的不准确信息。",
      welcome_1: "欢迎来到Yoyo聊天",
      welcome_2: "使用您的 YoyoAI 帐户登录以继续",
      "Log in": "登录",
      "Sign up": "注册",
      "Log in to ": "登录到",
      "Sign up to ": "注册到",
      "Email address": "邮箱地址",
      Password: "密码",
      "Create your account": "创建您的账户",
      Continue: "继续",
      email_success: "您已收到一封确认电子邮件。请检查您的收件箱。",
      "8_chars_valid": "密码必须包含至少 8 个字符。",
      email_valid: "请输入有效的电子邮件地址。",
      already_account: "已经有帐户？",
      already_email: "此邮箱号已被注册。去",
      pls_note_email: "请注意，您将收到一封确认电子邮件以验证您的帐户。",
      msg_error: "出了点问题，请重新问我。。。",
    },
  },
  en: {
    translation: {
      bottom_power_by:
        "Powered by OpenAI GPT. YoyoChat may produce inaccurate information about people, places, or facts.",
      welcome_1: "Welcome to YoyoChat",
      welcome_2: "Log in with your YoyoAI account to continue",
      email_success:
        "You've been sent a confirmation email. Please check your inbox.",
      "8_chars_valid": "Password must contain at least 8 characters.",
      email_valid: "Please enter a valid email address.",
      already_account: "Already have an account?",
      already_email: "This email is already registered. Go to",
      pls_note_email:
        "Please note that you will be sent a confirmation email to verify your account.",
      msg_error: "Error...",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "zh-CN", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
