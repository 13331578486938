import React from "react";
import { Typography } from "@mui/material";
import hljs from "highlight.js/lib/core";

type Props = { codeBlock: string[] };

/** 
    This accepts: 
    ** import React from 'react';
    ** import { useState } from 'react';
    ** import React, { useState } from 'react';
    */
const reactRegex = /^import\s+\w+|\{.+?\}\s+from\s+'react'(;)?$/gm;

const getLanguage = (codeBlock: string) => {
  const language = hljs.highlightAuto(codeBlock || "").language;
  return language;
};

const getLanguageTitle = (codeBlock: string) => {
  const language = hljs.highlightAuto(codeBlock || "").language;
  switch (language) {
    case "javascript":
      return "js";
    case "typescript":
      return "ts";
    case "cpp":
      return "c++";
    case "csharp":
      return "c#";
    case "xml":
      return "html";
    default:
      return language;
  }
};

const CodeMessageLine =
  React.memo<Props>(_CodeMessageLine);

function _CodeMessageLine({ codeBlock }: Props) {
  if (!codeBlock?.length) {
    return null;
  }

  const codeBlockString = codeBlock.join("\n").replace(/\t/g, "    ");

  let languageTitle = getLanguageTitle(codeBlockString);
  if (
    (languageTitle === "js" || languageTitle === "html") &&
    codeBlockString.match(reactRegex)
  ) {
    languageTitle = "jsx";
  } else if (languageTitle === "ts" && codeBlockString.match(reactRegex)) {
    languageTitle = "tsx";
  }

  return (
    // code block
    <div
    //  key={index}
    >
      <Typography
        // key={index}
        variant="body1"
        sx={{
          color: "#D1D5D2",
          fontFamily: "Noto Sans, sans-serif",
          fontSize: "0.75rem",
          lineHeight: "2.5",
          mt: "20px",
          backgroundColor: "#343541",
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
          mr: "1rem",
          pl: "1rem",
          pr: "1rem",
        }}
      >
        {languageTitle}
      </Typography>
      <Typography
        variant="body1"
        component={"pre"}
        sx={{
          color: "#D1D5D2",
          fontSize: "0.85rem",
          lineHeight: "1.4",
          bgcolor: "black",
          paddingTop: "0.25rem",
          overflowX: "auto",
          pl: "1rem",
          pr: "1rem",
          maxWidth: "100%",
          mr: "1rem",
          mb: "30px",
          borderBottomLeftRadius: "7px",
          borderBottomRightRadius: "7px",
          letterSpacing: "-0.2px",
        }}
      >
        <code
          className={`language-${getLanguage(codeBlockString)}`}
          style={{
            backgroundColor: "black",
            maxWidth: "100%",
            height: "100%",
            fontFamily: "FireCode",
          }}
        >
          {codeBlockString}
        </code>
      </Typography>
    </div>
  );
}

export default CodeMessageLine;
