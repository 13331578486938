import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useMemo, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useFirstFetchUser } from "@/components/sideBar";
import { useUpdate } from "react-use";
import { AuthUserData } from "@/logic/user";
import "./admin.css";
import Button from "@mui/material/Button";

// const rows = [
//   { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
//   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
//   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
//   { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
//   { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
//   { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
//   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
//   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
//   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
// ];

type UserRow = {
  _id: any;
  email: string;
  enableGPT4: boolean | undefined;
  createdAt: any;
};

type UsersDataTable_Props = {
  rows: UserRow[];
  columns: GridColDef[];
};

function UsersDataTable(props: UsersDataTable_Props) {
  const { rows, columns } = props;

  return (
    <div className="admin-page" style={{ width: "100%" }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}

function AdminPage() {
  const render = useUpdate();
  const authHeader = useAuthHeader();
  const [users, setUsers] = useState([] as UserRow[]);

  useFirstFetchUser(render);

  const { user: { isSuperAdmin } = {} } = AuthUserData.getData();

  const fetchUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/super-admin/getAllUser`, {
        headers: { Authorization: authHeader() },
      })
      .then((response) => {
        console.log("getAllUser", response.data);
        if (Array.isArray(response.data?.users)) {
          let rows = response.data.users.map((u: any) => ({ ...u, id: u._id }));
          setUsers(rows);
        }
      });
  };

  useMemo(() => {
    fetchUsers();
  }, []);

  const setGPT4Status = (_id: string, status: boolean) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/super-admin/openGPT4`,
        { updateUserId: _id, enableGPT4: status },
        {
          headers: { Authorization: authHeader() },
        }
      )
      .then((response) => {
        fetchUsers();
      });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      //   { field: "_id", headerName: "ID", width: 70 },
      { field: "email", headerName: "邮箱", width: 160 },
      {
        field: "enableGPT4",
        headerName: "GPT版本",
        width: 250,
        renderCell: (params) => {
          const enableGPT4 = params.row.enableGPT4;
          const _id = params.row._id;

          const title = enableGPT4 ? "GPT-4" : "GPT-3.5";
          return (
            <div className="gpt-col">
              <div className="gpt-col__title">{title}</div>
              {!enableGPT4 ? (
                <Button
                  onClick={() => setGPT4Status(_id, true)}
                  variant="contained"
                >
                  打开GPT-4
                </Button>
              ) : (
                <Button
                  onClick={() => setGPT4Status(_id, false)}
                  variant="outlined"
                >
                  关闭GPT-4
                </Button>
              )}
            </div>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "账号创建时间",
        width: 160,
      },
    ],
    []
  );

  const Loading = () => <h3>Loading...</h3>;

  return (
    <div>
      {isSuperAdmin ? (
        <UsersDataTable rows={users} columns={columns} />
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default AdminPage;
