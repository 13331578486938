import axios from "axios";
import { useMemo } from "react";
import { useAuthHeader } from "react-auth-kit";

const authAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 5000,
});

export const useAuthAxios = () => {
  const authHeader = useAuthHeader();

  useMemo(() => {
    authAxiosInstance.defaults.headers.Authorization = authHeader();
  }, []);

  return authAxiosInstance;
};
