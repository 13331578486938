import React, { useState, useEffect } from "react";
import { Typography, TextField, IconButton, Stack } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import ApiKey from "../utils/apiKey";
import { useTranslation } from "react-i18next";
import { genApiUrl } from "../utils/http";
import { socket } from "../http/io";
import { useOnResizeWidth } from "../hooks/useOnResizeWidth";
import { useResizeElement } from "../hooks/useResizeElement";
import { getDBUniqId } from "@/utils";
import {
  genLoadingMsgItem,
  genMsgItem,
  newChat,
  newMessage,
} from "@/logic/chat";
import { chatPathRegex } from "@/consts/chat";

const Footer = (props: {
  setHeight: (height: number) => void;
  newInput: string;
  openModal: () => void;
  canSetApi?: boolean;
  hasLeftRoom?: boolean;
}) => {
  const { canSetApi = false, hasLeftRoom = false } = props;
  const authHeader = useAuthHeader();
  const navigate = useNavigate();

  const [input, setInput] = useState<string>("");
  const [pasteHandler, setPasteHandler] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const { t } = useTranslation();

  const isBigWidth = width >= 1000;

  const footerLeft = isBigWidth && hasLeftRoom ? "260px" : "0";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length < input.length) {
      setPasteHandler(true);
      setTimeout(() => setPasteHandler(false), 100);
    }
    setInput(value);
  };

  useEffect(() => {
    if (props.newInput !== "") {
      const value = props.newInput.slice(1, props.newInput.length - 1);
      setInput(value);
    }
  }, [props]);

  useOnResizeWidth(width, setWidth);

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      handleSubmit();
    } else if (e.key === "Enter" && e.shiftKey === true) {
      setInput(input + "\n");
    } else {
      return;
    }
  };

  const getFooterHeight = () => {
    const footer = document.getElementById("Footer");
    if (footer) {
      return footer.clientHeight;
    } else {
      return 0;
    }
  };

  // useEffect(() => {
  //   const newHeight = getFooterHeight();
  //   if (newHeight !== height) {
  //     setHeight(newHeight);
  //     props.setHeight(newHeight);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [input, pasteHandler]);

  const { ref: footerRef } = useResizeElement(({ newHeight }) =>
    props.setHeight(newHeight)
  );

  useEffect(() => {}, [socket]);

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (canSubmit === false) return;
    const message = input.trim();
    setTimeout(() => setInput(""), 1);

    if (message === "") return;

    function localSaveMsgBeforeAjax(chatID: string) {
      // debugger;
      let newMsgId = getDBUniqId();
      const loadingMsgId = getDBUniqId();

      const newMsg = genMsgItem({ content: message, _id: newMsgId, chatID });
      newMessage(chatID!, newMsg);

      // add loading data
      newMessage(chatID!, genLoadingMsgItem(loadingMsgId, chatID));

      return {
        newMsgId,
        loadingMsgId,
      };
    }

    try {
      setCanSubmit(false);

      if (canSetApi && !ApiKey.get()) {
        props.openModal();
        return;
      }

      if (window.location.pathname === "/") {
        let newChatID = getDBUniqId();

        
        newChat({
          _id: newChatID,
          title: message.slice(0, 10),
        });
        navigate(`/c/${newChatID}`);
        
        const { newMsgId, loadingMsgId } = localSaveMsgBeforeAjax(newChatID);

        // return;
        const res = await axios.post(
          genApiUrl("/api/chat/createMessage"),
          {
            message: { role: "user", content: message },
            chatID: newChatID,
            key: ApiKey.get() || "",
            msg_id: newMsgId,
            loading_msg_id: loadingMsgId,
            isNewChat: true,
          },
          {
            headers: {
              Authorization: authHeader(),
              "socket-id": socket.id,
              "language-type": navigator.language,
            },
          }
        );

        if (window.location.pathname.match(chatPathRegex)) return;

        const { chatID } = res.data;
        if (window.location.pathname === "/") {
          navigate(`/c/${chatID}`);
        }
      } else if (window.location.pathname.match(chatPathRegex)) {
        const chatID = window.location.pathname.split("/")[2];

        const { newMsgId, loadingMsgId } = localSaveMsgBeforeAjax(chatID);

        // return;
        await axios.post(
          genApiUrl("/api/chat/createMessage"),
          {
            message: { role: "user", content: message },
            chatID,
            msg_id: newMsgId,
            loading_msg_id: loadingMsgId,
          },
          {
            headers: {
              Authorization: authHeader(),
              "socket-id": socket.id,
              "language-type": navigator.language,
            },
          }
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCanSubmit(true);
      return;
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (pasteHandler === false) {
      setPasteHandler(true);
      setTimeout(() => setPasteHandler(false), 100);
    } else {
      e.preventDefault();
      return;
    }
  };

  const handleChangeWidth = () => {
    if (width < 1000) {
      return "100vw";
    } else {
      return "calc(100vw - 260px)";
    }
  };

  const handleMT = () => {
    if (width < 1000) {
      return "0px";
    } else {
      return "32px";
    }
  };

  const handleBorderTop = () => {
    if (width < 1000) {
      return "2px solid hsla(0,0%,100%,.2)";
    } else {
      return "";
    }
  };

  return (
    <div
      ref={footerRef}
      id="Footer"
      style={{
        position: "absolute",
        bottom: "0px",
        width: handleChangeWidth(),
        borderTop: handleBorderTop(),
        left: footerLeft,
      }}
    >
      <form
        autoComplete="off"
        style={{ width: "100%", height: "100%", display: "flex" }}
        onSubmit={handleSubmit}
      >
        <Stack
          direction="column"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", height: "100%", mt: handleMT() }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <TextField
              id="input"
              autoFocus
              placeholder={t("Send a message") + "..."}
              variant="outlined"
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleEnter}
              onPaste={handlePaste}
              sx={{
                maxWidth: "768px",
                width: "90%",
                position: "relative",
                backgroundColor: "#40414F",
                borderRadius: "5px",
                color: "white",
                minHeight: "46px",
                fontFamily: "Noto Sans, sans-serif",
                mb: "10px",
                mt: "10px",
                boxShadow: "0px 0px 1px 1px #343541",
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "inherit",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderWidth: "inherit",
                  },
                "& .MuiInputBase-input": {
                  fontSize: "16px",
                  lineHeight: "1",
                  width: "calc(100% - 40px)",
                },
              }}
              InputProps={{
                style: {
                  color: "white",
                },
                endAdornment: (
                  <IconButton
                    type="submit"
                    sx={{
                      color: "#5A5B6B",
                      position: "absolute",
                      bottom: "9px",
                      right: "15px",
                      width: "30px",
                      height: "30px",
                      borderRadius: "10px",
                      "&:hover": { backgroundColor: "#202123" },
                    }}
                  >
                    <SendOutlinedIcon fontSize="small" />
                  </IconButton>
                ),
              }}
              multiline
              maxRows={8}
            />
            {/* {!!props.openModal && (
              <IconButton
                onClick={props.openModal}
                disableRipple
                sx={{ bgcolor: "#00A67E" }}
              >
                <KeyIcon sx={{ color: "#C5C5D2" }} />
              </IconButton>
            )} */}
          </div>
          <Typography
            variant="body2"
            sx={{
              color: "#C5C5D2",
              fontFamily: "Noto Sans, sans-serif",
              fontSize: "12px",
              mb: "15px",
              textAlign: "center",
            }}
          >
            {t("bottom_power_by")}
          </Typography>
        </Stack>
      </form>
    </div>
  );
};

export default Footer;
