// 导入 React 和 Material-UI 的组件
import React from "react";
import { Controller } from "react-hook-form";
import { Button, TextField, Paper, ListItemText } from "@mui/material";
import classes from "./GptsAssistant1.module.css";
import cn from "classnames";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuthHeader } from "react-auth-kit";
import { useGPTsAssist1 } from "./index.logic";

// 定义主组件
export const GptsAssistant1 = () => {
  const authHeader = useAuthHeader();

  const {
    reset,
    control,
    setValue,
    errors,
    onSubmit,
    handleFileChange,
    handleSubmit,
    assistList,
    onDel,
  } = useGPTsAssist1();

  const AssisList = () => (
    <>
      <h4>GPTs列表</h4>
      <List dense={true} className={cn(classes.list)}>
        {assistList.map((assist) => (
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => onDel(assist.assistId)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={assist.name} />
          </ListItem>
        ))}
      </List>
    </>
  );

  // 返回 JSX 元素
  const AssistSetting = () => {
    return (
      <Paper className={classes.root}>
        <Typography variant="h4" align="center">
          新建GPTs设置
        </Typography>
        <form className={cn(classes.form)} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.input}
                label="标题"
                variant="outlined"
                fullWidth
                error={!!errors.title}
                helperText={errors.title ? "标题不能为空" : ""}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.input}
                label="引导描述"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                error={!!errors.description}
                helperText={errors.description ? "描述不能为空" : ""}
              />
            )}
          />
          <Controller
            name="csv_files"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              console.log("csv_file field", field);
              return (
                <>
                  <input
                    // {...(field as any)}
                    className={classes.file}
                    type="file"
                    multiple={true}
                    accept=".csv,.xlsx,.docx,.txt,"
                    onChange={handleFileChange}
                  />
                  {errors.csv_files && (
                    <Typography color="error" align="center">
                      请选择一个 CSV 文件
                    </Typography>
                  )}
                </>
              );
            }}
          />
          <Button
            className={classes.input}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            提交
          </Button>
        </form>
      </Paper>
    );
  };

  return (
    <div className={cn(classes.parent)}>
      <div className={cn(classes.left)}>{AssisList()}</div>
      <div className={cn(classes.right)}>{AssistSetting()}</div>
    </div>
  );
};
