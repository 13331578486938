import { throttle } from 'lodash-es';
import { useMemo } from 'react';

export const useTrailingThrottleFn = (
  fn: (...args: any) => void,
  ms?: number
) =>
  useMemo(
    () => throttle(fn, ms || 200, { leading: false, trailing: true }),
    []
  );
