import { AxiosInstance } from "axios";

export type GetAllGPTsList_Rsp_Item = {
  assistId: string;
  name: string;
  files:
    | {
        id: string;
        name: string;
      }[]
    | undefined;
  instructions: string;
};

export type GetAllGPTsList_Rsp = GetAllGPTsList_Rsp_Item[];

type getAllGPTs_Rsp = {
  GPTsList: GetAllGPTsList_Rsp;
};

export const http_GPTsList = async (authAxiosInstance: AxiosInstance) => {
  const res = await authAxiosInstance.get<getAllGPTs_Rsp>(
    "/api/gpts/getAllGPTs"
  );

  return res;
};

export const http_delGPTs = async (
  authAxiosInstance: AxiosInstance,
  assistId: string
) => {
  const res = await authAxiosInstance.delete(`/api/gpts/delGPTs/${assistId}`);
  return res;
};
