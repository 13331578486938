import io from "socket.io-client";

const initIO = () => {
  const socket = io(process.env.REACT_APP_API_URL as string);

  socket.on("connect", () => {
    console.log("socket connect:", socket.id);
  });
  return socket;
};

// load js run socket
export let socket = initIO();
