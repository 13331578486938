import { useEffect } from "react";
import { useTrailingThrottleFn } from "./common/useTrailingThrottleFn";

export const useOnResizeWidth = (
  width: number,
  setWidth: (...args: any) => void
) => {
  const onResize = useTrailingThrottleFn(() => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [width]);
};
